.App {
  text-align: center;
  align-content: center;
}
.fadeOut{
     opacity:0;
     transition: width 0.4s 0.4s, height 0.4s 0.4s, opacity 0.4s;

}
.fadeIn{
     opacity:1;
     transition: width 0.4s, height 0.4s, opacity 0.4s 0.4s;
}
